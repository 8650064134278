import { useSpring, animated } from '@react-spring/web'
import { useMediaQuery } from '@kaliber/use-media-query'

import { ContainerLg } from '/features/buildingBlocks/Container'
import { EnterAnimation } from '/features/buildingBlocks/EnterAnimation'
import { MediaWindow } from '/features/pageOnly/MediaWindow'
import { Tag } from '/features/buildingBlocks/Tag'
import { AnimatedRadarLooping } from '/features/pageOnly/AnimatedRadar'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Timeline.css'

export function Timeline({ milestones, layoutClassName = undefined }) {
  const timelineRef = React.useRef(null)
  const [offsetTop, setOffsetTop] = React.useState(0)

  React.useEffect(
    () => {
      setOffsetTop(timelineRef?.current?.offsetTop)
    },
    []
  )

  return (
    <section data-x='timeline' ref={timelineRef} className={cx(styles.component, layoutClassName)}>
      <ContainerLg>
        <div className={styles.timelineContainer}>
          <Line markerCount={milestones.length} layoutClassName={styles.lineLayout} {...{ offsetTop }} />
          <ul className={styles.milestonesList}>
            {milestones.map((item, i) => (
              <Milestone key={`${item._key}_${i}`} {...{ item }} />
            ))}
          </ul>
        </div>
      </ContainerLg>
    </section>
  )
}

function Milestone({ item }) {
  const { label, year, description, image } = item

  return (
    <li className={styles.componentMilestone}>
      <EnterAnimation offset={0.5}>
        <div className={styles.imageContainer}>
          <MediaWindow aspectRatio={5 / 7} layoutClassName={styles.imageLayout} distanceOverride={20} {...{ image }} />
        </div>
      </EnterAnimation>
      <MilestoneInfo layoutClassName={styles.milestoneInfoLayout} {...{ label, year, description }} />
    </li>
  )
}

function MilestoneInfo({ label, year, description, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentMilestoneInfo, layoutClassName)}>
      <Tag label={year} colorScheme='blue' layoutClassName={styles.tagLayout} />
      <h2 className={styles.milestoneLabel}>{label}</h2>
      <p className={styles.milestoneDescription}>{description}</p>
    </div>
  )
}

function Line({ offsetTop, markerCount, layoutClassName = undefined }) {
  const [progress, setProgress] = React.useState(0)
  const headerHeight = 80

  React.useEffect(
    () => {
      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)

      function handleScroll() {
        setProgress(window.innerHeight / 2 + window.scrollY - offsetTop - headerHeight)
      }
    },
    [offsetTop]
  )

  const { height } = useSpring({
    from: { height: '0px' },
    to: { height: progress > 0 ? `${progress}px` : '0px' },
    config: { mass: 0.5, tension: 600, friction: 50, precision: 0.01, velocity: 0 },
  })


  return (
    <div className={cx(styles.componentLine, layoutClassName)}>
      <div className={styles.progressContainer}>
        <animated.span className={styles.progressBar} style={{ height }} />
      </div>
      <div className={styles.milestoneMarkersContainer}>
        {Array.from({ length: markerCount }).map((_, i) => <MilestoneMarker key={i} layoutClassName={styles.milestoneMarkerLayout} {...{ progress }} />)}
      </div>
    </div>
  )
}

function MilestoneMarker({ progress, layoutClassName = undefined }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false
  const markerRef = React.useRef(null)
  const [offsetTop, setOffsetTop] = React.useState(0)
  const offset = isViewportLg
    ? offsetTop + 150
    : offsetTop - 25
  const active = progress > offset

  React.useEffect(
    () => {
      setOffsetTop(markerRef?.current?.offsetTop)
    },
    [isViewportLg]
  )

  const style = useSpring({
    opacity: active ? 1 : 0,
    scale: active ? 1 : 0,
    config: { mass: 1, tension: 750, friction: 25 }
  })

  return (
    <div className={cx(styles.componentMilestoneMarker, layoutClassName)}>
      <span ref={markerRef} className={styles.markerDot}>
        <animated.span className={styles.activeMarkerDot} {...{ style }}>
          <AnimatedRadarLooping layoutClassName={styles.animatedRadarLayout} />
        </animated.span>
      </span>
    </div>
  )
}
