import styles from './AnimatedRadar.css'

export function AnimatedRadarLooping({ layoutClassName = undefined }) {
  return <AnimatedRadarBase className={cx(styles.componentLooping, layoutClassName)} />
}

export function AnimatedRadarOnceWhite({ layoutClassName = undefined }) {
  return <AnimatedRadarBase className={cx(styles.componentOnceWhite, layoutClassName)} />
}

export function AnimatedRadarOnceBlue({ layoutClassName = undefined }) {
  return <AnimatedRadarBase className={cx(styles.componentOnceBlue, layoutClassName)} />
}

function AnimatedRadarBase({ className }) {
  return (
    <span className={cx(styles.componentBase, className)}>
      <span className={styles.ring} />
    </span>
  )
}
